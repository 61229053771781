.account-deets {

}

.donate-headers {
    font-weight: bold;
}

.btn-donate-page {
    background-color: #3BAF84;
    color: white;
    padding: 0.9rem;
    font-family: 'Montserrat', sans-serif;
    border-radius: 0.5rem;
    font-weight: bold;
    font-size: large;
    text-align: center;
    text-decoration: none;
    margin: 1.3rem 0 1.3rem 0;
    border-width: 0;
}

@media (max-width: 768px) {
    .btn-donate-page {
        font-size: small;
    }
}    

@media(max-width: 480px) {
    .btn-donate-page {
        font-size: small;
    }
  }
  