.navbar {
  background-color: #3BAF84;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.small-logo {
  padding: 0.3rem 0 0.3rem 2rem;
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0 2rem 0 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.navbar ul li {
  margin: 15px 15px 0 15px;
}

.navbar ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.navbar ul li a:hover {
  color:lightgray;
  transition: 0.3s;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 50px;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
}

.btn-donate {
  background-color: #357DED;
  color: white;
  padding: 0.6rem;
  font-family: 'Montserrat', sans-serif;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: medium;
  text-align: center;
  text-decoration: none;
  margin: 1.3rem 0 0 0;
  border-width: 0;
}

@media (max-width: 985px) {
  .btn-donate {
    font-size: small;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .navbar {
    height: 5rem;
  }

  /* Hide the standard navbar items */
  .navbar ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    /* right: 0; */
    background-color: #3BAF84;
    width: 100%;
    /* padding: 20px; */
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
  }

  .navbar ul li {
    margin: 0 5rem;
    padding: 1rem;
    text-align: center;
  }

  /* Display the navbar items when the menu is open */
  .navbar ul.open {
    display: flex;
    padding-bottom: 2rem;
    transform: translateX(0);
    opacity: 1;
  }

  .small-logo {
    display: none;
  }

  /* Show the hamburger icon on mobile */
  .hamburger {
    display: flex;
  }

  .btn-donate {
    font-size: small;
  }

  /* Ensure the body content starts below the fixed navbar for mobile */
  body {
    margin-top: 70px;
  }
}


